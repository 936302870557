.login-page {
  display: flex;
  height: 100vh;
  flex-wrap: wrap; /* This will allow the divs to stack on smaller screens */
  margin-top: -25px;
}

.left-side, .right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.left-side {
  width: 54.5%;
  background: #EEEEEE;
  position: relative;
}

.right-side {
  width: 45.5%;
  background: url('/public/images/vectorbg.png');
}

@media (min-width: 768px) {
  /* On screens that are 768px or wider, each side will take up 50% width */
  .left-side, .right-side {
    width: 50%;
  }
}

.collage {
  justify-content: center;
  margin-top: 5%; /* Use percentage for scalable margins */
  max-width: 80%;
  height: auto;
}

.login-container {
  width: 90%; /* Set to a percentage to make it responsive */
  max-width: 473px; /* This will make sure the width doesn't exceed 473px */
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 2%; /* Use percentage for scalable padding */
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
}

.google-login-button {
  width: 80%;
  max-width: 312px; 
  height: auto; 
  border-radius: 7px;
  border: 0.87px solid #000;
  background: #FFF;
  margin-top: 2%; /* Use percentage for scalable margins */
  color: #000;
  font-family: 'AktivGrotesk';
  font-weight: 600;
  font-size: 18px;
  padding: 10px 0;
}

.heading {
  margin-top: 15%; /* Use percentage for scalable margins */
  margin-bottom: 5%; /* Use percentage for scalable margins */
  color: #495678;
  text-align: center;
  font-family: 'AktivGrotesk', sans-serif;
  font-size: 1.5vw; /* Using em will make this scalable based on parent font size */
  font-weight: 700;
  line-height: normal;
  letter-spacing: 5.25px;
}