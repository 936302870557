@font-face {
  font-family: 'AktivGrotesk';
  src: url('./fonts/AktivGrotesk-Regular.otf') format('opentype'); /* Regular */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./fonts/AktivGrotesk-Bold.otf') format('opentype'); /* Bold */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./fonts/AktivGrotesk-Medium.otf') format('opentype'); /* Medium */
  font-weight: 600;
  font-style: normal;
}

.show-desktop {
  display: none;
}
.show-mobile{
  display: block
}

@media only screen and (min-width: 600px) {
  .show-desktop {
    display: block;
  }
  .show-mobile {
    display: none;
  }
}

.not-found {
  font-size: 44px;
  font-family: AktivGrotesk;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  text-align: center;
}